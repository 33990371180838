import Vue from "vue";
import VueRouter from "vue-router";

import Home from "@/views/Home.vue";

import OrdersOverview from "@/views/Orders/Overview";
import OrdersListActive from "@/views/Orders/ListActive";
import OrdersListArchived from "@/views/Orders/ListArchived";
import OrderDetail from "@/views/Orders/OrderDetail";
import OrderConfigurator from "@/views/Orders/Configurator";

import OrderCreateService from "@/views/Orders/CreateService";
import OrderCreateSample from "@/views/Orders/CreateSample";

import OrdersSearch from "@/views/Orders/Search";
import OrdersSearchResult from "@/views/Orders/SearchResult";

import LayoutBackend from "@/layouts/variations/Backend.vue";
import LayoutBackendBoxed from "@/layouts/variations/BackendCustomer.vue";
import LayoutBackendManufacture from "@/layouts/variations/BackendManufacture.vue";

import CustomerShoppingCart from "@/views/Customer/ShoppingCart";
import CustomerShoppingCartList from "@/views/Customer/ShoppingCartList";
import CustomerOrderLogin from "@/views/Customer/OrderLogin";
import CustomerOrderConfirmation from "@/views/Customer/OrderConfirmation";

import ManufactureHome from "@/views/Manufacture/Home.vue";
import ManufactureArchive from "@/views/Manufacture/Archive.vue";
import ManufactureOrder from "@/views/Manufacture/Order.vue";
import manufactureSearchResult from "@/views/Manufacture/SearchResult.vue";

import UserProfile from "@/views/UserProfile";

import Report from "@/views/Report";

// Register Vue Router
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Base",
    component: LayoutBackend,
    children: [
      {
        path: "/:shopid",
        name: "Home",
        component: Home,
      },
      {
        path: "/:shopid/orders",
        name: "Aufträge",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "/:shopid/orders/overview",
            name: "Übersicht",
            component: OrdersOverview,
          },
          {
            path: "/:shopid/orders/active",
            name: "OrdersListActive",
            component: OrdersListActive,
          },
          {
            path: "/:shopid/orders/archived",
            name: "OrdersListArchived",
            component: OrdersListArchived,
          },
          {
            path: "/:shopid/orders/detail/:orderid",
            name: "OrderDetail",
            component: OrderDetail,
          },
          {
            path: "/:shopid/orders/configurator/:orderid",
            name: "OrderConfigurator",
            component: OrderConfigurator,
          },
          {
            path: "/:shopid/orders/createservice/:orderid",
            name: "OrderCreateService",
            component: OrderCreateService,
          },
          {
            path: "/:shopid/orders/createsample/:orderid",
            name: "OrderCreateSample",
            component: OrderCreateSample,
          },
          {
            path: "/:shopid/orders/search",
            name: "OrdersSearch",
            component: OrdersSearch,
          },
          {
            path: "/:shopid/orders/search/result",
            name: "OrdersSearchResult",
            component: OrdersSearchResult,
          },
        ],
      },
      {
        path: "/:shopid/userprofile",
        name: "UserProfile",
        component: UserProfile,
      },
      {
        path: "/:shopid/report",
        name: "Report",
        component: Report,
      },
    ],
  },
  {
    path: "/:shopid/customer",
    name: "Customer",
    component: LayoutBackendBoxed,
    children: [
      {
        path: "/:shopid/customer/shoppingcartlist",
        name: "Kunden Warenkorb - Liste",
        component: CustomerShoppingCartList,
      },
      {
        path: "/:shopid/customer/shoppingcart/:carttimestamp",
        name: "customerShoppingCart",
        component: CustomerShoppingCart,
        meta: { theme: "" },
      },
      {
        path: "/:shopid/customer/shoppingcart/acredo/:carttimestamp",
        name: "customerShoppingCart",
        component: CustomerShoppingCart,
        meta: { theme: "acredo" },
      },
      {
        path: "/:shopid/customer/orderlogin/",
        name: "customerOrderLogin",
        component: CustomerOrderLogin,
      },
      {
        path: "/:shopid/customer/orderconfirmation/:orderid",
        name: "Kunden Auftragsbestätigung",
        component: CustomerOrderConfirmation,
      },
    ],
  },
  {
    path: "/manufacture",
    name: "manufacture",
    component: LayoutBackendManufacture,
    children: [
      {
        path: "/manufacture/home",
        name: "manufactureHome",
        component: ManufactureHome,
      },
      {
        path: "/manufacture/archive",
        name: "manufactureArchive",
        component: ManufactureArchive,
      },
      {
        path: "/manufacture/order/:orderid",
        name: "manufactureOrder",
        component: ManufactureOrder,
      },
      {
        path: "/manufacture/search/result",
        name: "manufactureSearchResult",
        component: manufactureSearchResult,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
