<!-- Angepasste Kopie von BackendBoxed.vue für Backend-Bereich mit Kundenbereich (Ohne Sidebar) -->

<template>
  <base-layout :layout-classes="layoutClasses">
    <!-- Header Content -->
    <!-- Using the available v-slot, we can override the default Header content from layouts/partials/Header.vue -->
    <template #header>
      <!-- Header Content -->
      <div class="content-header">
        <!-- Left Section -->
        <div class="d-flex align-items-center">
          <!-- Logo -->
          <router-link
            v-if="shopId"
            :to="{
              name: 'Home',
              params: { shopid: shopId },
            }"
            class="font-w600 text-dual"
          >
            <img
              v-if="colorTheme == 'acredo'"
              src="@/assets/images/logo_acredo.svg"
              class="ml-1 h-20 d-inline-block"
            />
            <img
              v-else
              src="@/assets/images/logo_egf_white.svg"
              class="ml-1 w-25 d-inline-block"
            />
          </router-link>
          <!-- END Logo -->
        </div>
        <!-- END Left Section -->

        <!-- Right Section -->
        <div class="d-flex align-items-center">
          <!-- User Dropdown -->

          <b-dropdown
            v-if="routeName != 'customerShoppingCart'"
            size="sm"
            variant="dual"
            class="d-inline-block ml-2"
            menu-class="p-0 border-0 font-size-sm"
            right
            no-caret
          >
            <template #button-content>
              <img
                class="rounded"
                src="/img/avatars/avatar10.jpg"
                alt="Header Avatar"
                style="width: 18px;"
              />
              <span class="d-none d-sm-inline-block ml-1">{{ username }}</span>
              <i class="fa fa-fw fa-angle-down d-none d-sm-inline-block"></i>
            </template>
            <li>
              <div class="p-3 text-center bg-primary">
                <img
                  class="img-avatar img-avatar48 img-avatar-thumb"
                  src="/img/avatars/avatar10.jpg"
                  alt="Avatar"
                />
              </div>
              <div class="p-2">
                <h5 class="dropdown-header text-uppercase">
                  {{ $t("header.actions") }}
                </h5>
                <a
                  class="dropdown-item d-flex align-items-center justify-content-between cursor-pointer"
                  @click="logout"
                >
                  <span>{{ $t("header.logout") }}</span>
                  <i class="si si-logout ml-1"></i>
                </a>
              </div>
            </li>
          </b-dropdown>
          <!-- END User Dropdown -->
        </div>
        <!-- END Right Section -->
      </div>
      <!-- END Header Content -->

      <!-- Header Search -->
      <div
        id="page-header-search"
        class="overlay-header bg-white"
        :class="{ show: $store.state.settings.headerSearch }"
        @keydown.esc="() => $store.commit('headerSearch', { mode: 'off' })"
      >
        <div class="content-header">
          <b-form @submit.stop.prevent="onSubmit" class="w-100">
            <b-input-group size="sm">
              <b-input-group-prepend>
                <base-layout-modifier action="headerSearchOff" variant="danger">
                  <i class="fa fa-fw fa-times-circle"></i>
                </base-layout-modifier>
              </b-input-group-prepend>
              <b-form-input
                placeholder="Search or hit ESC.."
                v-model="baseSearchTerm"
              ></b-form-input>
            </b-input-group>
          </b-form>
        </div>
      </div>
      <!-- END Header Search -->

      <!-- Header Loader -->
      <div
        id="page-header-loader"
        class="overlay-header bg-primary"
        :class="{ show: $store.state.settings.headerLoader }"
      >
        <div class="content-header">
          <div class="w-100 text-center">
            <i class="fa fa-fw fa-circle-notch fa-spin text-primary"></i>
          </div>
        </div>
      </div>
      <!-- END Header Loader -->
    </template>
    <!-- END Header Content -->
  </base-layout>
</template>

<script>
import Auth from "@/store/auth";
import BaseLayout from "../Base";

export default {
  name: "LayoutBackend",
  components: {
    BaseLayout,
  },
  data() {
    return {
      shopId: null,
      // Override and set custom CSS classes to the container of each base layout element
      layoutClasses: {
        sideOverlay: "",
        sidebar: "",
        footer: "",
      },
      baseSearchTerm: "",
    };
  },
  methods: {
    onSubmit() {
      this.$router.push("/backend-boxed/search?" + this.baseSearchTerm);
    },
    eventHeaderSearch(event) {
      // When ESCAPE key is hit close the header search section
      if (event.which === 27) {
        event.preventDefault();
        this.$store.commit("headerSearch", { mode: "off" });
      }
    },
    logout() {
      Auth.dispatch("setJwt", "");
      Auth.dispatch("setName", "");
      Auth.dispatch("setShopId", "");
    },
  },
  mounted() {
    this.shopId = this.$route.params.shopid;
    document.addEventListener("keydown", this.eventHeaderSearch);
  },
  destroyed() {
    document.removeEventListener("keydown", this.eventHeaderSearch);
  },
  created() {
    // Set default elements for this layout
    this.$store.commit("setLayout", {
      header: true,
      sidebar: false,
      sideOverlay: false,
      footer: true,
    });

    // Set various template options
    this.$store.commit("headerStyle", { mode: "dark" });
    this.$store.commit("mainContent", { mode: "boxed" });
  },
  computed: {
    username: function() {
      return Auth.getters.name;
    },
    routeName() {
      return this.$route.name;
    },
    colorTheme() {
      return this.$store.state.settings.colorTheme;
    },
  },
};
</script>
