import { render, staticRenderFns } from "./OrderLogin.vue?vue&type=template&id=5fe4f338&scoped=true"
import script from "./OrderLogin.vue?vue&type=script&lang=js"
export * from "./OrderLogin.vue?vue&type=script&lang=js"
import style0 from "./OrderLogin.vue?vue&type=style&index=0&id=5fe4f338&prod&lang=scss&scoped=scoped"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fe4f338",
  null
  
)

export default component.exports